
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { emptyToLine } from "@/utils/common";
import CouponDiscountContent from "../components/coupon-discount-content.vue";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";

const campaign = namespace("campaign");
const store = namespace("store");
const fission = namespace("fission");
const marketing = namespace("marketing");
@Component({
  components: { FileUpload, Editor, CouponDiscountContent }
})
export default class CollectCustomerEdit extends Vue {
  @store.State storeData;
  @campaign.Action queryCampaignDetail;
  @marketing.Action queryFissionCampaign;
  @marketing.Action getFissionQrCodeUrl;
  @marketing.Action getWxSchemeUrl;
  dayjs = dayjs;
  emptyToLine = emptyToLine;
  campaignForm: any = {
    id: "111111",
    campaignName: "",
    campaignBeginTime: "",
    campaignEndTime: "",
    campaignImage: "",
    campaignDesc: "",
    isCoupon: "0",
    campaignContext: "",
    campaignImageColor: "rgb(251,132, 28)",
    prize: {
      bindCode: "",
      bindObj: {}
    },
    fissionPrize: {
      bindCode: "",
      cardTemplateResult: {},
      prizeAmount: "",
      prizeQty: ""
    },
    // 非业务直接参数
    time: [],
    bannerType: "default" // default customize
  };
  showLinkQr: boolean = false;
  get couponInfo() {
    return this.campaignForm.prize ? this.campaignForm.prize.bindObj : {};
  }
  campaignFormRules: any = {};
  get breadData() {
    return [
      { name: this.$t("v210801.fission-activity"), path: "/marketing/campaign/fission-campagin" },
      {
        name: this.$t("v210801.view-fission-activity")
      }
    ];
  }
  get isAdd() {
    return this.$route.name === "marketingCardTemplateAdd";
  }

  // get campaignFullUrl() {
  //   return `${process.env.VUE_APP_H5_SITE}/wx-jump?storeCode=${this.storeData &&
  //     this.storeData.storeCode}&urlType=h5&url=${encodeURIComponent(
  //     process.env.VUE_APP_H5_SITE + "/campaign/promotion?campaignCode=" + this.campaignForm.campaignCode
  //   )}`;
  // }
  campaignFullUrl = "pages/fission/view";
  created() {}
  mounted() {
    this.init();
  }
  get id() {
    return this.$route.params.id;
  }
  init() {
    this.queryFissionCampaign({ campaignCode: this.id }).then(data => {
      console.log("[ data ]", data);
      this.campaignForm = data.data;
    });
  }
  qrCode(url) {
    document.getElementById("qrcode").innerHTML = "";
    let qrcode = new QRCode("qrcode", {
      width: 120, //图像宽度
      height: 120, //图像高度
      colorDark: "#000000", //前景色
      colorLight: "#ffffff", //背景色
      typeNumber: 4,
      correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
    });
    qrcode.clear(); //清除二维码
    qrcode.makeCode(url); //生成另一个新的二维码
  }
  activeRow: any = {};
  storeQrUrl: string = "";
  handleComfirmCommand(command) {
    if (command === "push") {
      this.$router.push(`/marketing/campaign/fission-send-collect/${this.campaignForm.campaignCode}`);
    } else if (command === "copyLink") {
      // this.showLinkQr = true;
      // setTimeout(() => {
      //   this.qrCode(this.campaignFullUrl);
      // }, 200);
      console.log("[=============  ]");
      this.activeRow = this.campaignForm;
      this.showLinkQr = true;

      let page = `pages/fission/view`; // 生产
      // let page = `pages/fission/view?activeCode=${this.campaignForm.campaignCode}`;
      this.getFissionQrCodeUrl({
        campaignCode: this.campaignForm.campaignCode,
        page
      }).then(data => {
        console.log("[ data==== ]", data);
        this.storeQrUrl = data.data;
      });
      let pQuery = `activeCode=${this.campaignForm.campaignCode}`;
      console.log("[ pQuery ]", pQuery);
      let wechatParams = {
        businessCode: this.campaignForm.campaignCode,
        businessType: "fissionCampaign",
        path: "pages/fission/view", // 生产
        // path: "pages/index/index",
        query: decodeURIComponent(pQuery)
      };
      this.getWxSchemeUrl(wechatParams).then(res => {
        this.campaignFullUrl = res.data;
        console.log("[this.campaignFullUrl  ]", res.data);
      });
    }
  }
  handleDownloadQR() {
    // var imgs = document.getElementById("qrcode").getElementsByTagName("img");
    // var a = document.createElement("a");
    // a.download = "QR" + new Date().getTime() || "下载图片名称";
    // a.href = imgs[0].src;
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    var a = document.createElement("a");

    a.download = "QR" + new Date().getTime() || (this.$t("v210801.download-picture-name") as string);

    a.href = this.storeQrUrl;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  handleEdit() {
    this.$router.push(`/marketing/campaign/fission-campagin-add/${this.id}`);
  }
}
